import { get, post } from './axios'

const getNews = async (data: any) => {
  const res = await post('/website/news/getList')

  return res
}

const addNews = async (data: any) => {
  const res = await post('/website/news/insert', data)

  return res
}

export default {
  getNews,
  addNews,
}
