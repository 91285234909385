import axios from 'axios'
import config from '@/config/config'

const { VUE_APP_BASE_URL } = process.env

const instance = axios.create({
  baseURL: VUE_APP_BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

instance.interceptors.request.use(
  (config: any) => {
    try {
      config.headers.common = {
        token: '',
      }
    } catch (e) {
      console.log(e)

      throw e
    }

    return config
  },
  (error) => {
    console.log(error)

    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    const res = response.data

    return res
  },
  (error) => {
    console.log(error)

    return Promise.reject(error)
  }
)

export const get = async (url: string, params = {}): Promise<any> => {
  const data = await instance.get(url, { params }).then((data) => data)

  return data
}

export const post = async (url: string, params = {}): Promise<any> => {
  const data = await instance.post(url, params).then((data) => data)

  return data
}
